p {
  color: var(--minor);
}

.title {
  font-size: 1.375rem;
  font-weight: 900;
  text-align: center;
  margin-bottom: 20px;
  color: var(--minor);
}
.subtitle {
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  color: var(--minor);
}

.normal-text {
  font-size: 1rem;
  color: var(--minor);
}
