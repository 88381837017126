/* Googlefont Poppins CDN Link */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap"); */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Poppins", sans-serif; */
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background: var(--primary);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
nav .navbar {
  height: 100%;
  max-width: 1140px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: red; */
  padding: 0 24px;
}

.navbar .logo-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
}

.logo-container .desktop-hamburger {
  margin-inline-end: 20px;
  cursor: pointer;
}

.navbar .logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.navbar .logo a,
.navbar .logo .nav-link-text,
.navbar .logo .company-logo-name {
  font-size: 20px;
  color: white;
  text-decoration: none;
  font-weight: 600;
  text-transform: uppercase;
}

.company-logo-image-container {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  overflow: hidden;
  background-color: white;
  margin-inline-end: 10px;
  cursor: pointer;
}

.nav-links {
  /* line-height: 70px; */
  height: 100%;
  flex-grow: 1;
  padding: 20px;
  background-color: var(--major);
}
.links {
  display: flex;
  flex-direction: column;
}
.links li {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style: none;
  padding: 0px;
  margin-bottom: 14px;
}
.links li a,
.links li .nav-link-text {
  height: 100%;
  text-decoration: none;
  white-space: nowrap;
  color: var(--minor);
  font-size: 15px;
  font-weight: 500;
}
.links li:hover .htmlcss-arrow,
.links li:hover .js-arrow {
  transform: rotate(180deg);
}

.links li .arrow {
  /* background: red; */
  height: 100%;
  width: 22px;
  line-height: 70px;
  text-align: center;
  display: inline-block;
  color: var(--minor);
  transition: all 0.3s ease;
}
.links li .sub-menu {
  position: absolute;
  top: 70px;
  left: 0;
  line-height: 40px;
  background: #6ab13d;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 4px 4px;
  display: none;
  z-index: 2;
}
.links li:hover .htmlCss-sub-menu,
.links li:hover .js-sub-menu {
  display: block;
}
.links li .sub-menu li {
  padding: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.links li .sub-menu a {
  color: var(--minor);
  font-size: 15px;
  font-weight: 500;
}
.links li .sub-menu .more-arrow {
  line-height: 40px;
}
.links li .htmlCss-more-sub-menu {
  /* line-height: 40px; */
}
.links li .sub-menu .more-sub-menu {
  position: absolute;
  top: 0;
  left: 100%;
  border-radius: 0 4px 4px 4px;
  z-index: 1;
  display: none;
}
.links li .sub-menu .more:hover .more-sub-menu {
  display: block;
}
.search-box {
  position: relative;
  height: 40px;
  width: 150px;
}
.search-box i {
  position: absolute;
  height: 100%;
  width: 100%;
  line-height: 40px;
  text-align: center;
  font-size: 22px;
  color: var(--minor);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}
.search-box .input-box {
  position: absolute;
  right: calc(100% - 40px);
  top: 80px;
  height: 60px;
  width: 300px;
  background: #6ab13d;
  border-radius: 6px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s ease;
}
.navbar.showInput .search-box .input-box {
  top: 65px;
  opacity: 1;
  pointer-events: auto;
  background: #6ab13d;
}
.search-box .input-box::before {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  background: #6ab13d;
  right: 10px;
  top: -6px;
  transform: rotate(45deg);
}
.search-box .input-box input {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  height: 35px;
  width: 280px;
  outline: none;
  padding: 0 15px;
  font-size: 16px;
  border: none;
}
.nav-links .sidebar-logo {
  display: none;
}
/* .navbar .bx-menu {
  display: none;
} */

.more-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.link-icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.drawer-header {
  background-color: var(--primary);
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.avatar-container {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  overflow: hidden;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline-end: 10px;
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.drawer-user-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
}

.username-container {
  flex: 1;
}

.drawer-username-text {
  font-size: 14px;
  font-weight: 500;
  color: white;
  margin-bottom: 5px;
}

.business-box {
  background-color: #ebebeb;
  margin-inline-start: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px;
  border-radius: 100px;
}

.business-logo-container {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
  margin-inline-end: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.unread-count-container {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--errorRed);
  margin-inline-start: 5px;
}

@media (max-width: 650px) {
  .navbar .logo .company-logo-name {
    font-size: 14px;
  }
}
