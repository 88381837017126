.MuiStepLabel-iconContainer .Mui-active,
.MuiStepLabel-iconContainer .Mui-completed {
  color: var(--primary);
}

.step-circle {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: var(--primary);
}

.rfi-form-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-number-completed {
  color: white;
}

.industry-list-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.industry-item {
  margin: 6px;
  padding: 3px 6px;
  border-radius: 5px;
  cursor: pointer;
}

.two-columns-responsive {
  width: 100%;
  background-color: var(--major);
  padding: 20px 20px 20px 20px;
  border-radius: 5px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}

.half-width-column {
  width: 100%;
  margin-top: 20px;
}

.social-row-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0px;
}

.icon-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 6px;
  margin-inline-end: 0px;

  border-radius: 5px;
  padding: 8px;
  background-color: var(--lightGray);
  width: calc(22% - 6px);
  min-width: 90px;
}

@media screen and (min-width: 768px) {
  .two-columns-responsive {
    flex-direction: row;
  }
}
@media screen and (max-width: 650px) {
  .stepper-container {
    display: none;
  }
  .rfi-form-title-container {
    margin-bottom: 20px;
  }
}
