.screen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  background-color: var(--backgroundGray);
  position: relative;
}

.screen {
  width: 100vw;
  max-width: 1140px;
  min-height: 100vh;
  padding: 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.page {
  width: min(100vw, 1140px);
  max-width: 100%;
  min-height: 100vh;
  padding: 80px 24px 24px 24px;
}

.card {
  width: 100%;
  max-width: 400px;
  border-radius: 5px;
  padding: 24px;
  box-shadow: 0 3px 4px 0.6px rgba(0, 0, 0, 0.2);
}
.card-full {
  width: 100%;
  border-radius: 5px;
  padding: 24px;
  box-shadow: 0 3px 4px 0.6px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.mt-20 {
  margin-top: 20px;
}

.row-start {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

button {
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  background-color: var(--major);
  color: var(--minor);
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.btn {
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  min-width: 100px;
  cursor: pointer;
  transition: transform 250ms ease;
  position: relative;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.329);
}

.btn::after {
  position: absolute;
  content: "";
  display: block;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 5px;

  opacity: 0;
  /* -webkit-box-shadow: 9px 10px 8px -7px rgba(150, 150, 150, 1);
  -moz-box-shadow: 9px 10px 8px -7px rgba(150, 150, 150, 1); */
  box-shadow: 5px 5px 8px -7px rgb(99, 98, 98);
  transition: opacity 250ms ease;
  /* z-index: 10; */
}

.btn:hover {
  transform: translateY(-0.15em) scale(1.02);
}

.btn:hover::after {
  opacity: 1;
}

.loading-full-page {
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 920px) {
  .page {
    padding: 80px 10px 24px 10px;
  }
  .screen {
    padding: 10px;
  }
}

.Toastify__toast-body {
  font-size: 12px;
}
