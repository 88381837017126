.spinner {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.smallSpinner {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.spinner::after,
.smallSpinner::after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #6ab13d;
  border-color: #6ab13d transparent #6ab13d transparent;
  animation: spinner 1.2s linear infinite;
}

.smallSpinner::after {
  width: 26px;
  height: 26px;
  border: 3px solid #6ab13d;
  border-color: #6ab13d transparent #6ab13d transparent;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
